import * as React from "react";
import { graphql } from "gatsby";
import { SliceZone } from "@prismicio/react";

import components from "../components/slices";
import Layout from "../components/common/Layout";

import { constructActiveDoc } from "../utilities";

const IndexPage = ({ data }) => {
  if (!data) return null;
  const { settings, page } = data;

  return (
    <Layout settings={settings} activeDocMeta={constructActiveDoc(page)}>
      <SliceZone slices={page.data.body} components={components} />
    </Layout>
  );
};

export const query = graphql`
  query homepageQuery($lang: String) {
    settings: prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    page: prismicHome(lang: { eq: $lang }) {
      alternate_languages {
        uid
        type
        lang
      }
      lang
      type
      url
      data {
        meta_title
        meta_description
        meta_image {
          alt
          dimensions {
            height
            width
          }
          url
        }
        body {
          ... on PrismicHomeDataBodyPageDescription {
            id
            slice_type
            primary {
              has_red_title
              red_title
              description {
                richText
              }
              rich_text_title {
                richText
              }
            }
          }
          ... on PrismicHomeDataBodyHero {
            id
            slice_type
            primary {
              button_text
              button_link {
                url
              }
              isbutton
              image {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                alt
              }
              hero_title
              mobile_image {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
              }
            }
          }
          ... on PrismicHomeDataBodyInfo {
            slice_type
            items {
              id
              info_details {
                richText
              }
            }
          }
          ... on PrismicHomeDataBodyInfoPicture {
            id
            slice_type
            items {
              info_number
              info_prefix
              info_suffix
              info_label
            }
            primary {
              image_mobile {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                alt
              }
              info_image {
                gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
                alt
              }
            }
          }
          ... on PrismicHomeDataBodySmallTitleWithText {
            id
            slice_type
            primary {
              title1
              description {
                richText
              }
            }
          }
          ... on PrismicHomeDataBodyCustomNumberList {
            id
            slice_type
            items {
              id
              description {
                richText
              }
              image_number {
                gatsbyImageData(placeholder: BLURRED, layout: FIXED)
                alt
              }
            }
          }
          ... on PrismicHomeDataBodyCarousel {
            id
            slice_type
            items {
              brand_image {
                alt
                gatsbyImageData(layout: FIXED, placeholder: BLURRED)
                url
              }
              brand_link {
                url
                target
              }
            }
            primary {
              carousel_image {
                alt
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
              }
              subtitle {
                richText
              }
              title1
              carousel_image_mobile {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
              }
              carousel_image_tablet {
                gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
              }
            }
          }
          ... on PrismicHomeDataBodyBrandsSection {
            id
            slice_type
            items {
              brands_image {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                alt
              }
              brands
              brands_description
              button_link {
                url
                target
              }
              button_text
              division {
                richText
                text
              }
            }
          }
          ... on PrismicHomeDataBodyNetwork {
            id
            slice_type
            primary {
              title1
              subtitle {
                richText
              }
              map {
                gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
                alt
              }
            }
            items {
              paragraph {
                richText
              }
            }
          }
          ... on PrismicHomeDataBodySimpleImageCarousel {
            id
            slice_type
            items {
              image {
                gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
                alt
                url
              }
            }
          }
          ... on PrismicHomeDataBodyPageDescription {
            id
            primary {
              description {
                richText
              }
              has_button
              has_red_title
              red_title
              rich_text_title {
                richText
              }
              button_text
              button_link {
                url
                target
              }
            }
            slice_type
          }
        }
      }
    }
  }
`;

export default IndexPage;
